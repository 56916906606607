<template>
  <div class="a-slide-content">
    <div class="a-slide-content-wrapp">
      <div class="a-slide-content-wrapp-title" @click.stop="$emit('handleChange')" v-html="item.title"></div>
      <div class="firstSlider">
        <swiper :options="sliderOptions">
          <swiper-slide v-for="(subItem, i) in item.slides_section" :key="'swiper-i-' + i">
            <div class="slide-title" v-html="subItem.title"></div>
            <div class="slide-subtitle" v-html="subItem.subTitle"></div>
            <div class="slide-img">
              <img :src="baseUrl + subItem.image" alt="">
            </div>
            <a class="slide-button" v-if="subItem.buttonText && subItem.buttonLink" v-text="subItem.buttonText" target="_blank" :href="subItem.buttonLink"></a>
            <button class="slide-button" v-else-if="subItem.buttonText && subItem.buttonVideo" v-text="subItem.buttonText" @click="$emit('triggerVideo', subItem.buttonVideo)"></button>
          </swiper-slide>
          <div class="swiper-pagination custom-pag-swiper" id="pagination-type-three" slot="pagination"></div>
          <div class="swiper-button-prev custom-pag-3" id="type-three-prev" slot="button-prev"></div>
          <div class="swiper-button-next custom-pag-3" id="type-three-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    baseUrl: {
      type: String
    },
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      video: false,
      sliderOptions: {
        slidesPerView: 1.4,
        centeredSlides: true,
        spaceBetween: 80,
        navigation: {
          nextEl: '.swiper-button-next#type-three-next',
          prevEl: '.swiper-button-prev#type-three-prev'
        },
        pagination: {
          el: '.swiper-pagination#pagination-type-three',
          clickable: true
        }
      }
    }
  }
}
</script>
