import { AuthHttp } from '@/helpers/http-helper'
import router from '@/router/index'

const state = {
  baseUrl: '',
  slides: [],
  intro: {},
  page: null,
  introInfoPage: null,
  loading: true,
  mainSlides: []
}

const getters = {
  getSlide: state => state.slides,
  getBaseUrl: state => state.baseUrl,
  getIntro: state => state.intro,
  getIntroInfoPage: state => state.introInfoPage,
  getPage: state => state.page,
  getLoading: state => state.loading,
  getMainSlides: state => state.mainSlides
}

const mutations = {
  setSlides (state, slides) {
    state.slides = slides
  },
  setBaseUrl (state, url) {
    state.baseUrl = url
  },
  setIntro (state, intro) {
    state.intro = intro
  },
  setIntroInfoPage (state, introInfoPage) {
    state.introInfoPage = introInfoPage
  },
  setPage (state, page) {
    state.page = page
  },
  clearSlidesData (state) {
    state = {
      baseUrl: '',
      slides: [],
      intro: {},
      page: null,
      introInfoPage: null,
      mainSlides: []
    }
  },
  setMainSlides (state, data) {
    state.mainSlides = data
  },
  setLoading (state, loading) {
    state.loading = loading
  }
}

const actions = {
  searchSlides ({ commit }, id) {
    let req = id
    let slug = router.currentRoute.params?.id
    if (slug && isNaN(slug)) { req += '/' + slug }
    return AuthHttp.get('/slides/topics/' + req).then(resp => {
      commit('setSlides', resp.data.slides)
      commit('setIntro', resp.data.intro)
      commit('setIntroInfoPage', (resp.data.introInfoPage || null))
      commit('setBaseUrl', resp.data.mediaBaseUrl)
      return resp
    })
  },
  searchPage ({ commit }, slug) {
    return AuthHttp.get('/pages/' + slug).then(resp => {
      commit('setPage', resp.data)
      return resp
    })
  },
  clearSlide ({ commit }) {
    commit('clearSlidesData')
  },
  addLoading ({ commit }) {
    commit('setLoading')
  },
  searchMainSlides ({ commit }) {
    return AuthHttp.get('/sliders').then(resp => {
      commit('setMainSlides', resp.data)
      return resp
    })
  }
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  namespaced: true
}
