<template>
  <div class="a-slide-content" v-if="item.title || item.innerImg">
    <img v-if="item.innerImg" class="innerImg" :src="baseUrl + item.innerImg" alt=""/>
    <button type="button" v-if="item.title" class="a-slide-content-title" @click.stop="$emit('handleChange')">
      <span class="title" v-html="item.title"></span>
      <arrow-down></arrow-down>
    </button>
  </div>
</template>

<script>
import arrowDown from '@/components/icons/arrow-down'

export default {
  components: {
    arrowDown
  },
  props: {
    baseUrl: {
      type: String
    },
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
