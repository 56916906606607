<template>
  <span class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 25.312 13.5" :transform="direction === 'down' ? 'rotate(180)' : ''">
      <path id="Pfeil_outline_oben_icon_Pfad_"
      data-name="Pfeil_outline_oben_icon_Pfad "
      d="M9.5,32.656a.844.844,0,0,0,1.441.6L22.156,22.037,33.372,33.252a.843.843,0,1,0,1.193-1.192L22.753,20.247a.842.842,0,0,0-1.193,0L9.747,32.06A.839.839,0,0,0,9.5,32.656Z"
      transform="translate(-9.5 -20)" :fill="color"/>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    direction: {
      type: String,
      default: 'down'
    }
  }
}
</script>
<style scoped>
.icon{
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
}
</style>
