<template>
  <div class="a-slide-content">
    <div class="a-slide-content-wrapp" @click.self="$emit('handleChange')">
      <div class="a-slide-content-wrapp-tabs">
        <ul>
          <template v-for="(itm, i) in categories">
            <li :key="'slide-5-tabs-' + i"
            v-text="itm.title"
            :class="{ 'active': itm.id === activeCategory }"
            @click="handleActiveSlides(itm.id)"></li>
          </template>
        </ul>
      </div>
      <div class="firstSlider fullImage">
        <swiper ref="fiSlider" :options="sliderOptions">
          <swiper-slide v-for="(subItem, i) in defSlides" :key="'swiper-i-' + i">
            <div class="slide-title" v-text="subItem.title"></div>
            <div class="slide-subtitle" v-text="subItem.subtitle"></div>
            <div class="slide-img" :style="{backgroundImage: 'url('+ baseUrl + subItem.image +')'}">
              <!-- <img :src="" alt=""> -->
            </div>
            <div class="slide-content" v-html="subItem.content"></div>
            <a class="slide-button" v-if="subItem.buttonText && subItem.buttonLink" v-text="subItem.buttonText" target="_blank" :href="subItem.buttonLink"></a>
            <button class="slide-button" v-else-if="subItem.buttonText && subItem.buttonVideo" v-text="subItem.buttonText" @click="$emit('triggerVideo', subItem.buttonVideo)"></button>
          </swiper-slide>
          <div class="swiper-pagination custom-pag-5" id="pagination-type-five" slot="pagination"></div>
          <div class="swiper-button-prev custom" id="type-five-prev" slot="button-prev"></div>
          <div class="swiper-button-next custom" id="type-five-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    },
    catId: {
      default: null
    }
  },
  watch: {
    catId (val) {
      if (val) {
        this.activeCategory = parseInt(val)
        this.handleActiveSlides(this.activeCategory)
      } else {
        this.activeCategory = 1
        this.handleActiveSlides(this.activeCategory)
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.fiSlider.$swiper
    }
  },
  data () {
    return {
      defSlides: [],
      activeCategory: 1,
      categories: [
        {
          id: 1,
          title: 'Für eine weitere Person'
        },
        {
          id: 2,
          title: 'Für eine weiteres Geräte'
        }
      ],
      sliderOptions: {
        observer: true,
        observeParent: true,
        slidesPerView: 1.4,
        centeredSlides: true,
        spaceBetween: 80,
        initialSlide: 0,
        navigation: {
          nextEl: '.swiper-button-next#type-five-next',
          prevEl: '.swiper-button-prev#type-five-prev'
        },
        pagination: {
          el: '.swiper-pagination#pagination-type-five',
          clickable: true
        }
      }
    }
  },
  methods: {
    handleActiveSlides (id) {
      this.activeCategory = id
      this.defSlides = this.item?.slides_section?.filter(item => {
        return (this.activeCategory && parseInt(item.category) === this.activeCategory)
      })
      this.swiper.slideTo(0)
      this.swiper.update()
    }
  },
  mounted () {
    if (this.catId) this.activeCategory = parseInt(this.catId)
    this.handleActiveSlides(this.activeCategory)
  }
}
</script>
