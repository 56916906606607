import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import './assets/scss/style.scss'
import './registerServiceWorker'

Vue.config.productionTip = false
Vue.prototype.$window = window

// Vue.prototype.getIdFromSlug = function (slug, slides) {
//   if (slug !== undefined) {
//     if (isNaN(slug)) {
//       return slug
//     } else {
//       Object.keys(this.slides).forEach((key, index) => {
//         if (this.slides[key].slug === slug) {}
//       })
//     }
//   }
//   return null
// }

new Vue({
  router,
  store,
  created () {},
  render: h => h(App)
}).$mount('#app')
