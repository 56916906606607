<template>
    <div class="a-slide-content">
    <div class="a-slide-content-wrapp">
      <div class="a-slide-content-wrapp-title" @click.stop="$emit('handleChange')" v-html="item.title"></div>
      <div class="a-slide-content-wrapp-inner">
        <div v-for="(itm, i) in item.slides_section" :key="'typesix-'+ i" class="type-columns" @click.stop="$emit('slideToRel', parseInt(i+1))">
          <div class="type-columns_icon">
            <img :src="baseUrl + itm.icon" alt="">
          </div>
          <span class="type-columns_title" v-text="itm.title"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    }
  }
}
</script>
