<template>
  <div class="a-slide-content" @click.stop="handleMoreInfo()">
    <div class="a-slide-content-wrapp">
      <div class="a-slide-content-wrapp-title" v-html="item.title"></div>
      <div class="a-slide-content-wrapp-raw">
        <span class="clipPath"></span>
        <span class="circles before">
          <img :src="circleShape" />
        </span>
        <span class="a-slide-content-wrapp-raw_innerCont" v-html="item.content"></span>
        <span class="circles after">
          <img :src="circleShape" />
        </span>
      </div>
    </div>
    <button v-if="item.moreInfoButton == 1" @click.stop="moreInfo = true" class="tariffe-more-info btn btn-link">{{ item.moreInfoButtonText }}</button>
    <more-info v-on:closePopup="moreInfo = false" v-if="moreInfo"></more-info>
  </div>
</template>

<script>

import MoreInfo from '@/components/moreInfo'
export default {
  components: { MoreInfo },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      moreInfo: false,
      circleShape: require('@/assets/images/intro13/bg/circle-shape.svg')
    }
  },
  methods: {
    handleMoreInfo () {
      !this.moreInfo && this.$emit('handleChange')
    }
  }
}
</script>
